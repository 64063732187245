import React from "react";

import "../styles/sass/noscript.scss";
import "../styles/sass/main.scss";
import SEO from "../components/seo/SEO";
import ImageSlider from "../components/imageSlider/ImageSlider";

const BASE_URL = "https://siverybo.sirv.com/Images/degroeneletters";

const imgsToPreload = [
  "https://siverybo.sirv.com/Images/degroeneletters/img2.jpg",
];

export default function IndexPage() {
  const headerRef = React.useRef();
  const currentDay = new Date().getDay();
  const [showImage, setShowImage] = React.useState(false);

  const cacheImages = async (images) => {
    const promises = await images.map((image) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = image;
        img.onload = resolve();
        img.onerror = reject();
      });
    });
    return promises;
  };

  React.useEffect(() => {
    cacheImages(imgsToPreload).then(() => {
      setShowImage(true);
    });
  }, []);

  return (
    <div id="wrapper">
      <div
        style={{ display: showImage ? "block" : "none" }}
        className="bg fixed fade-in-image"
      ></div>
      <SEO />
      <div id="intro">
        <h1 data-sal="slide-up" data-sal-duration="500" data-sal-delay="200">
          De Groene Letters
        </h1>
        <p data-sal="slide-up" data-sal-duration="500" data-sal-delay="400">
          Eigentijdse bloemen creaties.
        </p>
        <ul
          className="actions"
          data-sal="slide-up"
          data-sal-duration="500"
          data-sal-delay="600"
        >
          <li>
            <div
              tabIndex="-3"
              role="button"
              className="button icon solid solo fa-arrow-down scrolly"
              onClick={() => headerRef.current.scrollIntoView()}
              onKeyDown={() => headerRef.current.scrollIntoView()}
            >
              Verdergaan
            </div>
          </li>
        </ul>
      </div>
      <header ref={headerRef} id="header">
        <div className="logo" aria-label="De Groene Letters logo">
          De Groene Letters
        </div>
      </header>
      <div id="main">
        <article className="post featured">
          <header className="major">
            <span className="date">Welkom bij</span>
            <h2>
              <div>De Groene Letters</div>
            </h2>
            <p>
              In februari 2017 ging de deur van onze gezellige bloemenwinkel
              open.
            </p>
            <p className="text-align-left">
              <span className="image right no-mobile">
                <img
                  src={`${BASE_URL}/freddy-en-lieve.png`}
                  height="300"
                  style={{ objectFit: "contain" }}
                  alt="freddy en lieve"
                />
              </span>
              Freddy, Laurien en Lieve verwelkomen je met een brede glimlach.
              Met onze passie voor bloemen en planten, maken we elk stuk uniek.
              Bij ons staan kwaliteit, service én de klant centraal. We kiezen
              steeds zelf de bloemen en planten uit om de beste kwaliteit en
              versheid te garanderen.
            </p>
            <p className="text-align-left">
              Met meer dan 35 jaar ervaring als gediplomeerd plantentechnicus,
              bezit Freddy een uitgebreide kennis om iedereen met raad en daad
              bij te staan bij de aankoop, het schikken en aanplanten van
              bloemen en planten.
            </p>
            <p className="text-align-left">
              <span className="image left no-mobile">
                <img
                  src={`${BASE_URL}/laurien-en-freddy.png`}
                  height="400"
                  style={{ objectFit: "contain" }}
                  alt="laurien en freddy"
                />
              </span>
              <br />
              Ben je op zoek naar een mooi boeket, een kamerplant of kleine
              decoratie, als geschenk voor jezelf of voor een ander? Of schenk
              je liever een cadeaubon? Kom dan zeker een kijkje nemen in de
              gezelligste bloemenwinkel van de buurt!
            </p>
            <p className="text-align-left">
              {" "}
              We zijn er ook voor iedereen in moeilijkere tijden. We maken
              individueel bloemwerk voor alle gelegenheden: verjaardag,
              bruidswerk, rouwbloemen, met Allerheiligen ... Geen enkele
              uitdaging gaan we uit de weg om voor jullie iets buitengewoons te
              maken.
            </p>
          </header>
        </article>

        <section className="posts">
          <article>
            <ImageSlider
              title={"Boeketten"}
              minCount={1}
              maxCount={3}
              basePath={`${BASE_URL}/boeketten/boeketten`}
            />
            <p>
              Je vindt steeds een ruime keuze aan dagverse boeketten in de
              winkel. Wil je graag een boeket op maat? We stellen graag een
              uniek boeket voor je samen!
            </p>
            <p>
              Onze voorkeur gaat steeds uit naar Belgische kwekers en veilingen.
              Zo proberen we ook onze Belgische economie te ondersteunen.
            </p>
          </article>
          <article>
            <ImageSlider
              title={"Bloemstukken"}
              minCount={1}
              maxCount={9}
              basePath={`${BASE_URL}/bloemstukken/bloemstukken`}
            />
            <p>
              We maken heel graag gepersonaliseerde bloemstukken op bestelling,
              in nauw overleg met de klant. Alles kan!
            </p>
          </article>
          <article>
            <ImageSlider
              title={"Bruidswerk"}
              minCount={1}
              maxCount={12}
              basePath={`${BASE_URL}/bruidswerk/bruidswerk`}
            />
            <p>
              De dag van jouw huwelijk is een zeer bijzondere dag. We verzorgen
              graag jouw bruidsboeket en alle bijhorende feestbloemen voor de
              zaal, kerk, auto …
            </p>
            <p>
              We nemen graag op afspraak de tijd om dit tot in de puntjes te
              bespreken, zodat we kunnen bijdragen aan jouw perfecte trouwdag.
            </p>
          </article>
          <article>
            <ImageSlider
              title={"Rouwbloemen"}
              minCount={1}
              maxCount={16}
              basePath={`${BASE_URL}/rouwbloemen/rouwbloemen`}
            />
            <p>
              Afscheid nemen van een geliefde, familielid of vriend is een
              emotioneel moment. Wil je een bloemstuk of rouwboeket voorzien
              voor bij de laatste groet of bij de thuisbewaring?
            </p>
            <p>
              {" "}
              We nemen graag de tijd om samen met jou alle verschillende
              mogelijkheden te bespreken. Indien gewenst, worden de bloemstukken
              ter plaatse geleverd.
            </p>
          </article>
        </section>
        <section
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            className="image main"
            src={`/assets/images/flyer-2024.png`}
            alt="Allerheiligen"
          />
          <article>
            <p>
              We organiseren ook jaarlijks een opendeurweekend voor
              Allerheiligen. Je kan de modellen tijdens dat weekend bekijken en
              bestellen in de winkel. We maken ook graag een uniek stuk,
              speciaal voor jou! We kondigen dit steeds aan op onze website en
              op Facebook.
            </p>
          </article>
        </section>
        <section
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <header>
            <h2 style={{ textAlign: "center", width: "100%" }}>
              Wilt u graag iets bestellen?
            </h2>
            <p
              style={{ fontWeight: "bold", textAlign: "center", width: "100%" }}
            >
              Minstens 24u op voorhand bestellen!
            </p>
            <p
              style={{ fontWeight: "bold", textAlign: "center", width: "100%" }}
            >
              Alle bestellingen die geleverd moeten worden dienen op voorhand
              betaald te zijn. (bevestiging van overschrijving/storting via
              email)
            </p>
            <p style={{ textAlign: "center", width: "100%" }}>
              Bestellen kan door te mailen naar{" "}
              <a
                style={{ fontWeight: "bold" }}
                aria-label="Mail naar de groene letters"
                href="mailto:degroeneletters@telenet.be"
              >
                degroeneletters@telenet.be
              </a>
              .
            </p>
          </header>
        </section>
      </div>
      <footer id="footer">
        <section>
          <h3 className="heading">Openingsuren:</h3>
          <ul>
            <li className={currentDay === 1 ? "currDay" : ""}>
              Maandag: Gesloten
            </li>
            <li className={currentDay === 2 ? "currDay" : ""}>
              Dinsdag: 13:00 - 18:00
            </li>
            <li className={currentDay === 3 ? "currDay" : ""}>
              Woensdag: 9:30 - 12:00 &amp; 13:00 - 18:00
            </li>
            <li className={currentDay === 4 ? "currDay" : ""}>
              Donderdag: 9:30 - 12:00 &amp; 13:00 - 18:00
            </li>
            <li className={currentDay === 5 ? "currDay" : ""}>
              Vrijdag: 9:30 - 12:00 &amp; 13:00 - 18:00
            </li>
            <li className={currentDay === 6 ? "currDay" : ""}>
              Zaterdag: 9:00 - 18:00
            </li>
            <li className={currentDay === 0 ? "currDay" : ""}>
              Zondag: 9:00 - 13:00
            </li>
            <li>Alle feestdagen geopend (zie aankondiging)</li>
          </ul>
          <hr />
          <h3>Facebook pagina</h3>
          <ul className="icons alt">
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                aria-label="Facebook pagina van de groene letters"
                href="https://www.facebook.com/degroeneletters/"
                className="icon brands alt fa-facebook-f"
              >
                <span className="label">Facebook</span>
              </a>
            </li>
          </ul>
        </section>
        <section className="split contact">
          <section className="alt">
            <h3>Adres</h3>
            <p>
              <a
                target="_blank"
                rel="noreferrer"
                aria-label="Google maps locatie van de groene letters"
                href="https://www.google.com/maps/place/Generaal+de+Wittestraat+6%2Fbus+1,+3545+Halen/@50.9482296,5.1109203,17z/data=!3m1!4b1!4m5!3m4!1s0x47c13e8c1aca3e77:0x99e5949425bf4b7d!8m2!3d50.9482296!4d5.113109"
              >
                Generaal de Wittestraat 6 bus 1<br />
                3545 Halen
              </a>
            </p>
          </section>
          <section className="phone-number">
            <h3>Telefoonnummer</h3>
            <p>
              <a href="tel:+3213269292" aria-label="Bel naar De Groene Letters">
                tel: 013 26 92 92
              </a>
            </p>
          </section>
          <section className="phone-number">
            <h3>GSM</h3>
            <p>
              <a
                href="tel:+32485116413"
                aria-label="Bel naar Freddy Zeelmaekers"
              >
                0485/11.64.13
              </a>
            </p>
          </section>
          <section>
            <h3>Emailadressen</h3>
            <div className="multi-section">
              <p>
                <a
                  href="mailto:degroeneletters@telenet.be"
                  aria-label="Mail naar De Groene Letters"
                >
                  degroeneletters@telenet.be
                </a>
              </p>
              <p>
                <a
                  href="mailto:freddy_zeelmaekers@hotmail.com"
                  aria-label="Mail naar Freddy Zeelmaekers"
                >
                  freddy_zeelmaekers@hotmail.com
                </a>
              </p>
            </div>
          </section>
          <section>
            <h3>Rekeningnummers</h3>
            <div className="multi-section">
              <p>(KBC) BE28 7350 5273 2420</p>
              <p>(AXA) BE38 7512 0832 0472</p>
            </div>
          </section>
        </section>
      </footer>

      <div id="copyright">
        <ul>
          <li>&copy;{new Date().getFullYear()}</li>
          <li>
            Made by{" "}
            <a
              href="https://www.webtric.be"
              target="_blank"
              rel="noreferrer"
              aria-label="Creator - Joachim Zeelmaekers"
            >
              Joachim Zeelmaekers
            </a>
          </li>
          <li>
            Website powered by{" "}
            <a
              href="https://www.webtric.be"
              target="_blank"
              rel="noreferrer"
              aria-label="Creator - Webtric"
            >
              Webtric
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
