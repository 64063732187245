const config = {
  siteTitle: 'De groene letters | bloemenwinkel in Halen', // Site title.
  siteTitleShort: 'De Groene Letters', // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: 'De groene letters | Bloemen kopen in halen', // Alternative site title for SEO.
  siteLogo: '/assets/logo-1024.png', // Logo used for SEO and manifest.
  siteUrl: 'https://www.degroeneletters.be', // Domain of your website without pathPrefix.
  pathPrefix: '/', // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  siteDescription:
    '[De Groene letters] Ben je op zoek naar een mooi boeket, een kamerplant of kleine decoratie, als geschenk voor jezelf of voor een ander? Of schenk je liever een cadeaubon? Kom dan zeker een kijkje nemen in de gezelligste bloemenwinkel van de buurt!',
  siteRss: '/rss.xml', // Path to the RSS file.
  siteRssTitle: 'De groene letters | Bloemen kopen in halen', // Title of the RSS feed
  siteFBAppID: '', // FB Application ID for using app insights
  googleAnalyticsID: 'UA-163694767-1', // GA tracking ID.
  disqusShortname: 'https-de-groene-letters-website', // Disqus shortname.
  dateFromFormat: 'YYYY-MM-DD', // Date format used in the frontmatter.
  dateFormat: 'DD/MM/YYYY', // Date format for display.
  postsPerPage: 4, // Amount of posts displayed per listing page.
  userName: 'Freddy Zeelmaekers', // Username to display in the author segment.
  userEmail: 'degroeneletters@telenet.be', // Email used for RSS feed's author segment
  userTwitter: '', // Optionally renders "Follow Me" in the UserInfo segment.
  userLocation: 'Halen, België', // User location to display in the author segment.
  userAvatar: '',
  userDescription: '',
  // Links to social profiles/projects you want to display in the author segment/navigation bar.
  userLinks: [
    {
      label: 'Facebook',
      url: 'https://www.facebook.com/degroeneletters/',
      iconClassName: 'fab fa-facebook',
    },
    {
      label: 'Email',
      url: 'mailto:degroeneletters@telenet.be',
      iconClassName: 'fa fa-envelope',
    },
  ],
  copyright: 'Copyright © 2020. Joachim Zeelmaekers | Webtric', // Copyright string for the footer of the website and RSS feed.
  themeColor: '#FFFF', // Used for setting manifest and progress theme colors.
  backgroundColor: '#212931', // Used for setting manifest background color.
};

// Validate

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === '/') {
  config.pathPrefix = '';
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, '')}`;
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === '/')
  config.siteUrl = config.siteUrl.slice(0, -1);

// Make sure siteRss has a starting forward slash
if (config.siteRss && config.siteRss[0] !== '/')
  config.siteRss = `/${config.siteRss}`;

module.exports = config;
