import React from 'react';

function ImageSlider({
  title,
  maxCount,
  basePath,
  minCount = 1,
  extension = 'png',
}) {
  const [counter, setCounter] = React.useState(minCount);
  const currentImagePath = `${basePath}-${counter}.${extension}`;

  const addCount = () => {
    if (counter === maxCount) {
      setCounter(minCount);
      return;
    }

    setCounter((prevState) => prevState + 1);
  };

  const subtractCount = () => {
    if (counter === minCount) {
      setCounter(maxCount);
      return;
    }
    setCounter((prevState) => prevState - 1);
  };

  return (
    <>
      <header>
        <h2>{title}</h2>
      </header>
      <div className="image fit">
        <div className="fit-300">
          <img id="flower" src={currentImagePath} alt="" />
        </div>
        <div
          tabIndex="-1"
          className="prev"
          role="button"
          onKeyDown={subtractCount}
          onClick={subtractCount}
          aria-label="Vorige"
        >
          &#10094;
        </div>
        <div
          tabIndex="-2"
          className="next"
          role="button"
          onClick={addCount}
          onKeyDown={addCount}
          aria-label="Volgende"
        >
          &#10095;
        </div>
      </div>
    </>
  );
}

export default ImageSlider;
